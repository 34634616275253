/**
 * Top menu
 */
.MuiAppBar-root {
  z-index: 1201 !important;
}
.MuiDrawer-paper {
  overflow-x: hidden;
}

.nav-brand {
  margin-right: 12px;
}
.nav-link {
  padding: 0 3px 1px 3px;
  margin: 0 9px -18px 9px;
  color: inherit;
  border-bottom: 6px solid transparent;
  text-decoration: none;
}

/**
 * Page layout
 */
.home {
  background-image: url('../public//an-aerial-photograph-of-the-flooded-tana-river-which-is-in-the-rift-valley-c5cc1b-1024.jpg');
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

article {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.25rem;
  letter-spacing: -0.5px;
  line-height: 1.45;
}
@media (min-width: 600px) {
  article {
    font-size: 1.6rem;
  }
}
article .centred,
article.centred {
  margin: 0 auto 1em;
  max-width: 50rem;
  padding: 0 1em;
}
article .centred.wide,
article.centred.wide {
  max-width: 75rem;
}
article .minibar {
  width: 3rem;
  margin: 4rem 0 0;
  height: 4px;
  border: 0;
  background-color: #222;
}

/**
 * Map and controls
 */
.mapboxgl-ctrl-logo {
  display: none !important;
}

tr.group-env,
tr.group-soc,
tr.group-econ,
tr.group-header {
  border-left-width: 4px;
  border-left-style: solid;
}
tr.group-env {
  border-color: #92ce61;
}
tr.group-soc {
  border-color: #5d8cc5;
}
tr.group-econ {
  border-color: #cf9a5a;
}
tr.group-header {
  border-color: #aaa;
}
